import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { fixture } from "./fixture";

import "./featuredprojects.scss";

const FeaturedProjects = () => {
  const [projects, setProjects] = useState([]);
  const [infoFromBackend, setInfoFromBackend] = useState(false);

  useEffect(() => {
    axios
      .get("https://api.fundwave.es/homepage") // PENDIENTE: Crear las .env variables
      .then((response) => {
        const fetchedProjects = response.data.projects.mostVisited.slice(0, 4);
        setInfoFromBackend(true);
        setProjects(fetchedProjects);
      })
      .catch((error) => {
        setProjects(fixture.projects.mostVisited.slice(0, 4));
        console.error("Error al obtener los proyectos:", error);
      });
  }, []);

  return (
    <section className="mdl-featured-projects pt-5 pb-5">
      <Container>
        <Row className="mb-5 row align-items-center d-flex">
          <Col md={6}>
            <h2>
              Tu apoyo, su futuro: <br />
              cada contribución cuenta.
            </h2>
            <p>
              Únete a nuestra misión de impulsar proyectos que marcan la
              diferencia en las comunidades de todo el mundo.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {!infoFromBackend && (
              <h3 style={{ color: "pink" }}>Información desde el Fixture</h3>
            )}
            <Row>
              {projects.map((project) => (
                <Col md={6} className="mdl-project__it mb-5" key={project.id}>
                  <Row>
                    <Col md={6}>
                      <img
                        src={project.img_url || "/imgs/img-intro.jpg"}
                        alt={project.title}
                        className="img-fluid mb-5 mb-md-0"
                      />
                    </Col>
                    <Col md={6}>
                      <span>#{project.category}</span>
                      <h3>{project.title}</h3>
                      <p>
                        {project.description.length > 100
                          ? project.description.slice(0, 100) + "..."
                          : project.description}
                      </p>
                      <Button variant="link">Llegir més</Button>
                    </Col>
                  </Row>
                </Col>
              ))}
              <Col md={12} className="d-flex justify-content-center">
                <Button variant="primary" size="lg" style={{ width: '200px' }}>
                  Explorar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FeaturedProjects;
