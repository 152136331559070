import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./legals.scss";

export const LegalAdvice = () => {
  return (
    <div>
      <section className="mdl-hero-in">
        <Container>
          <Row>
            <Col md={12} className="col-md-12 d-flex justify-content-center">
              <h1 className="title">Avís legal</h1>
            </Col>
          </Row>
        </Container>
        
      </section>
      
      <section className="mdl-hero-in bg-white">
        <Container style={{ maxWidth: '800px' }}>
          <Row>
            <Col md={12} className="d-flex justify-content-center flex-column align-items-start">
                <h2>1. Dades identificatives</h2>
                <p>En compliment de la normativa vigent, i concretament amb el que estableix la Llei 34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i Comerç Electrònic (LSSICE), i el Reglament (UE) 2016/679, del Parlament Europeu i del Consell, de 27 d'abril de 2016, relatiu a la protecció de les persones físiques pel que fa al tractament de dades personals, us informem de les dades identificatives de l'empresa responsable d'aquest lloc web:</p>
                <ul>
                    <li><strong>Nom de l'empresa:</strong> FundWave</li>
                    <li><strong>NIF/CIF:</strong> B23123654</li>
                    <li><strong>Adreça:</strong> C/ XXXX, XX, Barcelona, Espanya</li>
                    <li><strong>Correu electrònic:</strong> <a href="mailto:info@fundwave.es">info@fundwave.es</a></li>
                    <li><strong>Telèfon:</strong> +34 635 273 847</li>
                </ul>

                <h2>2. Propietat intel·lectual i industrial</h2>
                <p>Tots els continguts d’aquest lloc web (textos, imatges, disseny gràfic, logotips, icones, codi font, bases de dades, així com qualsevol altre element susceptible de protecció per drets de propietat intel·lectual) són propietat de FundWave o, si escau, de tercers que han autoritzat el seu ús. Es prohibeix qualsevol ús, reproducció, distribució, transformació o comunicació pública sense l'autorització expressa de FundWave o dels titulars dels drets corresponents.</p>

                <h2>3. Condicions d'ús</h2>
                <p>L'accés a aquest lloc web i la utilització dels seus serveis implican l'acceptació plena de les condicions generals d'ús que es detallen a continuació:</p>
                <ul>
                    <li><strong>Ús de la web: </strong> Els usuaris s'obliguen a utilitzar aquest lloc web d’acord amb la llei, els drets de propietat intel·lectual i industrial, i les condicions d'ús establertes.</li>
                    <li><strong>Contingut: </strong> FundWave no es responsabilitza de l'ús indegut dels continguts del lloc web ni de les accions que es derivin d’aquest ús.</li>
                </ul>
                <p>Exclusió de responsabilitat: FundWave no garanteix la disponibilitat i continuïtat del funcionament de la web. En cap cas serà responsable per danys directes o indirectes derivats de l'ús de la pàgina o dels serveis.</p>

                <h2>4. Política de privacitat</h2>
                <p>La privacitat dels nostres usuaris és una prioritat per a nosaltres. FundWave compleix amb la normativa vigent sobre protecció de dades personals, incloent el Reglament (UE) 2016/679 (RGPD) i la Llei Orgànica 3/2018, de 5 de desembre, de Protecció de Dades Personals i garantia dels drets digitals. Per obtenir informació detallada sobre com recollim, utilitzem i protegim les dades personals, consulteu la nostra Política de Privacitat.</p>

                <h2>5. Política de cookies</h2>
                <p>Aquest lloc web utilitza cookies per millorar l'experiència d'usuari, oferir serveis personalitzats i realitzar anàlisis de trànsit web. En continuar navegant, accepteu l'ús de cookies d'acord amb la nostra Política de Cookies.</p>

                <h2>6. Enllaços a altres pàgines web</h2>
                <p>Aquest lloc web pot contenir enllaços a altres pàgines web gestionades per tercers. FundWave no es fa responsable del contingut ni de les pràctiques de privacitat d’aquestes pàgines.</p>

                <h2>7. Modificacions del lloc web i les condicions d'ús</h2>
                <p>FundWave es reserva el dret de modificar, actualitzar o eliminar el contingut del lloc web així com les condicions d'ús en qualsevol moment i sense previ avís. És responsabilitat de l'usuari consultar regularment les condicions d'ús i altres textos legals del lloc web</p>

                <h2>8. Legislació aplicable i jurisdicció</h2>
                <p>Les condicions d'ús i tot el que es derivin del lloc web es regiran per la legislació espanyola. En cas de qualsevol conflicte o disputa derivada de l'ús d'aquest lloc web, les parts es sotmeten als jutjats i tribunals de Barcelona.</p>

            </Col>
          </Row>
        </Container>
      </section>
      
    </div>
  );
};
