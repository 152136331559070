import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { fixture } from "./fixture";
import Marquee from "react-fast-marquee";
import { ProjectContext } from "../../../context/ProjectContext";

import "./index.scss";

const HeroProjects = () => {
  const { projects, loading, error } = useContext(ProjectContext);
  const [localProjects, setLocalProjects] = useState([]);
  const [infoFromBackend, setInfoFromBackend] = useState(false);

  useEffect(() => {
    axios
      .get("https://api.fundwave.es/homepage") // PENDIENTE: Crear las .env variables
      .then((response) => {
        const fetchedProjects = response.data.projects.mostVisited.slice(0, 4);
        setInfoFromBackend(true);
        setLocalProjects(fetchedProjects);
      })
      .catch((error) => {
        setLocalProjects(fixture.projects.mostVisited.slice(0, 4));
        console.error("Error al obtener los proyectos:", error);
      });
  }, []);

  if (loading) {
    return <p>Cargando nombres...</p>;
  }

  if (error) {
    return <p className="text-danger">{error}</p>;
  }

  return (
    <Marquee className="mb-3" direction="right">
      {(infoFromBackend ? localProjects : projects).map((project) => (
        <div className="marquee-item" key={project.id}>
          <img
            src={project.img_url || "/imgs/img-intro.jpg"}
            alt={project.title}
            className="img-fluid"
          />
          <span>#{project.category}</span>
          <span>@user</span>
          <h3>{project.title}</h3>
        </div>
      ))}
    </Marquee>
  );
};

export default HeroProjects;
