import React, { useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.scss";

function Footer() {
  const [email, setEmail] = useState("");

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (email) {
      alert(`Gràcies per subscriure't a la nostra newsletter, ${email}`);
      setEmail("");
    } else {
      alert("Si us play, introdueix un correu elentrònic vàlid.");
    }
  };

  return (
    <footer className="footer pt-5 pb-5">
      <Container>
        <Row>
          <Col md={3}>
            <h4>fundwave</h4>
            <ul>
              <li>
                <Link to="about">Qui som</Link>
              </li>
              <li>
                <Link to="/contact">Contacte</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/support">Suport</Link>
              </li>
            </ul>
          </Col>
          <Col md={3}>
            <h4>més informació</h4>
            <ul>
              <li>
                <Link to="legal-cookies">Política de cookies</Link>
              </li>
              <li>
                <Link to="legal-privacy">Política de privacidad</Link>
              </li>
              <li>
                <Link to="legal-advice">Avís legal</Link>
              </li>
            </ul>
            <p>Copyright © 2024 fundwave. <br /> Tots els drets reservats.</p>
          </Col>
          <Col md={6}>
            <h5>Subscriu-te a la newsletter</h5>
            <p>Rep les últimes noticies i actualitzacions de FundWave.</p>
            <Form onSubmit={handleSubscribe}>
              <Form.Group controlId="newsletterEmail">
                <Form.Control
                  type="email"
                  placeholder="Introdueix el teu correu electrònic"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mb-3"
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-2">
                Subscriure'm
              </Button>
            </Form>
            <div className="social-icons mt-3">
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3"
              >
                <i className="bi bi-instagram fs-5"></i>
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3"
              >
                <i className="bi bi-twitter fs-5"></i>
              </a>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-youtube fs-5"></i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
