import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectContext } from "../../context/ProjectContext";

function ProjectList() {
  const { projects, categories, loading, error } = useContext(ProjectContext);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const location = useLocation(); // Per llegir els paràmetres de la URL
  const navigate = useNavigate(); // Per actualitzar la URL dinàmicament

  // Llegir categories des de la URL en carregar
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryParams = params.get("category");
    if (categoryParams) {
      const categoriesFromURL = categoryParams.split(",");
      setSelectedCategories(categoriesFromURL);
    } else {
      setSelectedCategories([]);
    }
  }, [location.search]);

  // Filtrar projectes segons les categories seleccionades
  useEffect(() => {
    if (selectedCategories.length === 0) {
      setFilteredProjects(projects);
    } else {
      const filtered = projects.filter((project) =>
        selectedCategories.includes(project.category)
      );
      setFilteredProjects(filtered);
    }
  }, [projects, selectedCategories]);

  // Gestionar selecció i deselecció de categories
  const handleCategoryClick = (category) => {
    const isSelected = selectedCategories.includes(category);
    const updatedCategories = isSelected
      ? selectedCategories.filter((c) => c !== category) // Deseleccionar
      : [...selectedCategories, category]; // Seleccionar

    setSelectedCategories(updatedCategories);

    // Actualitzar la URL amb les categories seleccionades
    const params = new URLSearchParams();
    if (updatedCategories.length > 0) {
      params.set("category", updatedCategories.join(","));
    }
    navigate(`?${params.toString()}`);
  };

  // Esborrar filtres
  const clearFilters = () => {
    setSelectedCategories([]);
    navigate("/projects"); // Esborrar paràmetres de la URL
  };

  if (loading) {
    return <div className="text-center mt-5">Carregant...</div>;
  }

  if (error) {
    return <div className="alert alert-danger mt-5">{error}</div>;
  }

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Llista de projectes</h1>
      <p>
        Descobreix tots els projectes publicats a FundWave, una plataforma que
        connecta persones amb idees transformadores amb aquells que volen
        fer-les realitat. Filtra per categories per trobar iniciatives que
        s'ajustin als teus interessos, des de projectes d'educació fins a
        iniciatives mediambientals i socials. Uneix-te a la comunitat que
        impulsa el canvi i contribueix a donar suport als projectes que
        importen.
      </p>
      {/* Filtres de categories */}
      <div className="mb-4">
        <div className="d-flex flex-wrap align-items-center">
          {categories.map((category) => (
            <button
              key={category}
              className={`btn me-2 mb-2 ${
                selectedCategories.includes(category)
                  ? "btn-primary"
                  : "btn-outline-primary"
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
          {/* Botó per esborrar filtres */}
          {selectedCategories.length > 0 && (
            <button className="btn btn-danger ms-3 mb-2" onClick={clearFilters}>
              Esborrar<span className="ms-1">✖</span>
            </button>
          )}
        </div>
      </div>

      {/* Llistat de projectes */}
      <div className="row">
        {filteredProjects.map((project) => (
          <div className="col-md-4 mb-4" key={project.id}>
            <div className="card">
              <img
                src={project.img_url}
                className="card-img-top"
                alt={project.title}
                style={{ height: "200px", objectFit: "cover" }}
              />
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
                <p className="card-text">
                  {project.description.substring(0, 100)}...
                </p>
                <p className="card-text">
                  <strong>Categoria:</strong> {project.category}
                </p>
                <p className="card-text">
                  <strong>Objectiu:</strong> $
                  {project.financial_goal.toLocaleString()}
                </p>
                <a href={`/project/${project.id}`} className="btn btn-primary">
                  Veure detalls
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProjectList;
