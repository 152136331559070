import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function ProjectDetails() {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(
          `https://api.fundwave.es/projects/${id}`
        );
        setProject(response.data[0]);
      } catch (err) {
        setError("Error fetching project data");
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  if (loading) {
    return <div className="text-center mt-5">Carregant...</div>;
  }

  if (error) {
    return <div className="alert alert-danger mt-5">{error}</div>;
  }

  if (!project) {
    return <div className="alert alert-warning mt-5">Project not found</div>;
  }

  return (
    <div className="container mt-5">
      <div className="card">
        <img
          src={project.img_url}
          className="card-img-top"
          alt={project.title}
        />
        <div className="card-body">
          <h3 className="card-title">{project.title}</h3>
          <p className="card-text">{project.description}</p>
          <p className="card-text">
            <strong>Category:</strong> {project.category}
          </p>
          <p className="card-text">
            <strong>Financial Goal:</strong> $
            {project.financial_goal.toLocaleString()}
          </p>
          <p className="card-text">
            <strong>Current Amount:</strong> $
            {project.actual_amount.toLocaleString()}
          </p>
          <p className="card-text">
            <strong>Status:</strong> {project.status}
          </p>
          <p className="card-text">
            <strong>Deadline:</strong>{" "}
            {new Date(project.deadline).toLocaleDateString()}
          </p>
          <div className="d-flex align-items-center">
            <img
              src={project.user.img_url}
              alt={project.user.name}
              className="rounded-circle me-3"
              style={{ width: "50px", height: "50px" }}
            />
            <p className="mb-0">
              <strong>By:</strong> {project.user.name}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
