import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

// Crear el contexto
export const ProjectContext = createContext();

// Proveedor del contexto
export const ProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get("https://api.fundwave.es/projects");
        const projectsData = response.data.projects;
        setProjects(projectsData);

        // Crear un array único de categorías
        const uniqueCategories = [
          ...new Set(projectsData.map((project) => project.category)),
        ];
        setCategories(uniqueCategories);
      } catch (err) {
        setError("Error fetching projects");
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  return (
    <ProjectContext.Provider value={{ projects, categories, loading, error }}>
      {children}
    </ProjectContext.Provider>
  );
};
