import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import axios from "axios";
import { UserContext } from "../../context/UserContext";

const ProfilePage = () => {
  const { token, logout } = useContext(UserContext);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState(null);
  const [passwordSuccess, setPasswordSuccess] = useState(null);

  // Obtenir la informació de l'usuari quan es carrega la pàgina
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://api.fundwave.es/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data.user); // Ajustar segons l'estructura
      } catch (err) {
        setError("Error en carregar la informació de l'usuari.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchUserData();
    } else {
      setError("No s'ha trobat un token d'autenticació.");
      setLoading(false);
    }
  }, [token]);

  // Gestionar el canvi d'imatge de perfil
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("profile_pic", file);

    try {
      setUploading(true);
      const response = await axios.put(
        "https://api.fundwave.es/user/change-profile-pic",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setUser((prevUser) => ({
        ...prevUser,
        img_url: response.data.img_url, // Actualitza la URL de la imatge
      }));
    } catch (err) {
      console.error("Error en canviar la imatge del perfil:", err);
      setError("No s'ha pogut actualitzar la imatge del perfil.");
    } finally {
      setUploading(false);
    }
  };

  // Gestionar el canvi de contrasenya
  const handleChangePassword = async () => {
    setPasswordError(null);
    setPasswordSuccess(null);

    if (passwordData.password !== passwordData.confirmPassword) {
      setPasswordError("Les contrasenyes no coincideixen.");
      return;
    }

    try {
      await axios.put(
        "https://api.fundwave.es/user/change-password",
        { password: passwordData.password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPasswordSuccess("Contrasenya actualitzada correctament.");
      setPasswordData({ password: "", confirmPassword: "" });
    } catch (err) {
      console.error("Error en canviar la contrasenya:", err);
      setPasswordError("No s'ha pogut actualitzar la contrasenya.");
    }
  };

  if (loading) {
    return <p className="text-center mt-5">Carregant...</p>;
  }

  if (error) {
    return <p className="text-danger text-center mt-5">{error}</p>;
  }

  return (
    <Container className="mt-5 mb-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-lg">
            <Card.Body className="p-4">
              <Row className="align-items-center">
                <Col md={4} className="text-center">
                  <label
                    htmlFor="profile-pic-upload"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={
                        user?.img_url ||
                        "https://img.freepik.com/vector-premium/ilustracion-plana-vectorial-escala-gris-profilo-usuario-avatar-imagen-perfil-icono-persona-profilo-negocio-mujer-adecuado-perfiles-redes-sociales-iconos-protectores-pantalla-como-plantillax9xa_719432-1333.jpg"
                      }
                      alt={`${user?.first_name} ${user?.last_name}`}
                      className="rounded-circle img-fluid"
                      style={{ maxWidth: "150px" }}
                    />
                  </label>
                  <Form.Control
                    type="file"
                    id="profile-pic-upload"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                  {uploading && (
                    <p className="text-muted mt-2">Actualitzant...</p>
                  )}
                </Col>
                <Col md={8}>
                  <h3>
                    {user?.first_name} {user?.last_name}
                  </h3>
                  <p className="text-muted">
                    <strong>Correu:</strong> {user?.email}
                  </p>
                  <p className="text-muted">
                    <strong>Data de creació:</strong>{" "}
                    {new Date(user?.created_at).toLocaleDateString()}
                  </p>
                  <p className="text-muted">
                    <strong>Última actualització:</strong>{" "}
                    {new Date(user?.updated_at).toLocaleDateString()}
                  </p>
                  <Button variant="danger" onClick={logout} className="mt-3">
                    Tanca sessió
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modificar contrasenya */}
      <Row className="justify-content-center mt-5">
        <Col md={8}>
          <Card className="shadow-lg">
            <Card.Body className="p-4">
              <h4>Modificar contrasenya</h4>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Nova contrasenya</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Introdueix la nova contrasenya"
                    value={passwordData.password}
                    onChange={(e) =>
                      setPasswordData({
                        ...passwordData,
                        password: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Confirma la contrasenya</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirma la nova contrasenya"
                    value={passwordData.confirmPassword}
                    onChange={(e) =>
                      setPasswordData({
                        ...passwordData,
                        confirmPassword: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                {passwordError && (
                  <p className="text-danger">{passwordError}</p>
                )}
                {passwordSuccess && (
                  <p className="text-success">{passwordSuccess}</p>
                )}
                <Button
                  variant="primary"
                  onClick={handleChangePassword}
                  disabled={!passwordData.password || uploading}
                >
                  Actualitzar contrasenya
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePage;
