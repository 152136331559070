import React, { useContext } from "react";
// import { Button } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { ProjectContext } from "../../../context/ProjectContext"; // Importar el contexto

import "./index.scss";

const HeroProjects = () => {
  const { projects, loading, error } = useContext(ProjectContext); // Accede al contexto global

  return loading ? (
    <p>Cargando nombres...</p>
  ) : error ? (
    <p className="text-danger">{error}</p>
  ) : (
    <Marquee className="mb-3">
      {projects.map((project) => (
        <div className="marquee-item" key={project.id}>
          <img
            src={project.img_url || "/imgs/img-intro.jpg"}
            alt={project.title}
            className="img-fluid"
          />
          <span>#{project.category}</span>
          <span>@user</span>
          <h3>{project.title}</h3>
          {/*<Button variant="link">veure més</Button>*/}
        </div>
      ))}
    </Marquee>
  );
};

export default HeroProjects;
