import React, { useState, useContext } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../context/ProjectContext";
import { UserContext } from "../../context/UserContext";

const CreateProjectPage = () => {
  const { categories } = useContext(ProjectContext); // Obtenir les categories del context
  const { token } = useContext(UserContext); // Obtenir el token de l'usuari
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    financial_goal: "",
    category: "",
    deadline: "",
    img: null, // Per a emmagatzemar la imatge seleccionada
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, img: files[0] }); // Gestionar imatge
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    // Validar camps obligatoris
    if (
      !formData.title ||
      !formData.description ||
      !formData.financial_goal ||
      !formData.category ||
      !formData.deadline
    ) {
      setError("Si us plau, completa tots els camps obligatoris.");
      return;
    }

    try {
      setLoading(true);

      const projectData = new FormData();
      projectData.append("title", formData.title);
      projectData.append("description", formData.description);
      projectData.append("financial_goal", formData.financial_goal);
      projectData.append("category", formData.category);
      projectData.append("deadline", formData.deadline);
      if (formData.img) {
        projectData.append("img", formData.img);
      }

      const response = await axios.post(
        "https://api.fundwave.es/projects/create",
        projectData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setSuccess("Projecte creat amb èxit.");
      setFormData({
        title: "",
        description: "",
        financial_goal: "",
        category: "",
        deadline: "",
        img: null,
      });

      // Redirigir a la pàgina de detalls del projecte
      navigate(`/project/${response.data.id}`);
    } catch (err) {
      console.error("Error al crear el projecte:", err);
      setError(
        "Hi ha hagut un problema al crear el projecte. Torna-ho a intentar."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5 mb-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-lg">
            <Card.Body>
              <h3 className="mb-4 text-center">Crear Projecte</h3>
              {error && <p className="text-danger">{error}</p>}
              {success && <p className="text-success">{success}</p>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Títol</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Introdueix el títol del projecte"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Descripció</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Descriu el teu projecte"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Objectiu financer (€)</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Introdueix l'objectiu financer"
                    name="financial_goal"
                    value={formData.financial_goal}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Categoria</Form.Label>
                  <Form.Select
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    <option value="">Selecciona una categoria</option>
                    {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Data límit</Form.Label>
                  <Form.Control
                    type="date"
                    name="deadline"
                    value={formData.deadline}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Imatge del projecte</Form.Label>
                  <Form.Control
                    type="file"
                    name="img"
                    accept="image/*"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button
                  type="submit"
                  variant="primary"
                  className="w-100"
                  disabled={loading}
                >
                  {loading ? "Creant..." : "Crear Projecte"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateProjectPage;
