import React from "react";
import { Link } from "react-router-dom";

import { Container, Row, Col, Button } from "react-bootstrap";
import HeroProjects from "../../../components/Home/Hero/HeroProjects";
import HeroProjectsRight from "../../../components/Home/Hero/HeroProjectsRight";

const Hero = () => {
  return (
    <section className="hero-section">
      <Container>
        <Row>
          <Col md={6}>
            <h1>
              Impulsa grandes ideas, <br />
              juntos hacemos la diferencia
            </h1>
            <p>
              En FundWave, conectamos innovadores con personas que creen en el
              poder de las buenas ideas. ¡Descubre y apoya proyectos que
              importan!
            </p>
            <Button
              as={Link}
              to="/projects"
              variant="primary"
              size="xl"
              style={{ width: "200px" }}
              className="mt-4"
            >
              Explorar
            </Button>
          </Col>
          <Col
            md={6}
            className="c-col col-md-6 d-flex flex-column position-relative"
          >
            <HeroProjects />
            <HeroProjectsRight />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
