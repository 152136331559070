import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import { ProjectContext } from "../../../context/ProjectContext";
import { UserContext } from "../../../context/UserContext";

import "./navbar.scss";

const NavBar = () => {
  const { categories } = useContext(ProjectContext); // Accede al contexto de categorías
  const { user, logout } = useContext(UserContext); // Accede al contexto de usuario
  const navigate = useNavigate();

  // Maneja la redirección al perfil
  const handleProfileClick = () => {
    navigate("/profile");
  };

  return (
    <Navbar expand="lg" className="header bg-body-primary sticky-top">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            src="/imgs/logo-fundwave.png"
            alt="Logo fundwave"
            className="img-fluid"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link as={Link} to="/projects">
              Projectes
            </Nav.Link>
            {/* Desplegable dinámico de categorías */}
            <NavDropdown title="Categories" id="navbarScrollingDropdown">
              {categories.length > 0 ? (
                categories.map((category) => (
                  <NavDropdown.Item
                    as={Link}
                    to={`/projects?category=${encodeURIComponent(category)}`}
                    key={category}
                  >
                    {category}
                  </NavDropdown.Item>
                ))
              ) : (
                <NavDropdown.Item disabled>
                  No hi ha categories
                </NavDropdown.Item>
              )}
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/projects">
                Veure tot
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/about">
              Sobre nosaltres
            </Nav.Link>
            <Nav.Link as={Link} to="/project/create">
              <b>Publicar</b>
            </Nav.Link>
          </Nav>
          {/* Sección de usuario */}
          <Nav className="ms-auto">
            {user ? (
              <>
                <Button
                  variant="link"
                  className="text-primary ms-3"
                  onClick={handleProfileClick}
                >
                  Hola, {user.first_name}
                </Button>
                <Button
                  variant="outline-danger"
                  className="ms-3"
                  onClick={logout}
                >
                  Tancar Sessió
                </Button>
              </>
            ) : (
              <Nav.Link as={Link} to="/login">
                Accedir
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
