import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import { About } from "./pages/About";
import { LegalAdvice } from "./pages/Legals/LegalAdvice";
import { LegalPrivacy } from "./pages/Legals/LegalPrivacy";
import { LegalCookies } from "./pages/Legals/LegalCookies";
import { Home } from "./pages/Home";
import LoginPage from "./pages/User/Login";
import SignupPage from "./pages/User/SignUp";
import ProjectDetail from "./pages/ProjectDetail";
import ProjectList from "./pages/ProjectList";
import CreateProjectPage from "./pages/ProjectCreate";

import { ProjectProvider } from "./context/ProjectContext";
import { UserProvider, useUser } from "./context/UserContext";
import ProfilePage from "./pages/User/Profile";

// Componente para proteger rutas
const ProtectedRoute = ({ children }) => {
  const { user, token } = useUser();

  // Verificar si el usuario está autenticado
  if (!user || !token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  return (
    <UserProvider>
      <ProjectProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="signup" element={<SignupPage />} />
              <Route path="projects" element={<ProjectList />} />
              <Route path="project/:id" element={<ProjectDetail />} />
              <Route path="project/create" element={<CreateProjectPage />} />
              <Route
                path="profile"
                element={
                  <ProtectedRoute>
                    <ProfilePage />
                  </ProtectedRoute>
                }
              />
              {/*Legal pages*/}
              <Route path="legal-advice" element={<LegalAdvice />} />
              <Route path="legal-privacy" element={<LegalPrivacy />} />
              <Route path="legal-cookies" element={<LegalCookies />} />
            </Route>
          </Routes>
        </div>
      </ProjectProvider>
    </UserProvider>
  );
}

export default App;
