// src/components/Hero.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Intro = () => {
  return (
    <section className="mdl-intro pt-5 pb-5">
      <Container>
        <Row className="mb-5 row align-items-center d-flex">
          <Col md={6}>
            <h2>¿Por qué Fundwave?</h2>
          </Col>
          <Col md={6}>
            <p>
              En FundWave, conectamos innovadores con personas que creen en el
              poder de las buenas ideas. ¡Descubre y apoya proyectos que
              importan!
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <img
              src="/imgs/img-intro.jpg"
              alt="Imagen de equipo"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Intro;
