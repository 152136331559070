import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";

// Esquema de validació amb Yup
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("El nom és obligatori"),
  last_name: Yup.string().required("Els cognoms són obligatoris"),
  email: Yup.string()
    .email("Introdueix un correu electrònic vàlid")
    .required("El correu electrònic és obligatori"),
  password: Yup.string()
    .min(6, "La contrasenya ha de tenir almenys 6 caràcters")
    .required("La contrasenya és obligatòria"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Les contrasenyes han de coincidir")
    .required("Has de confirmar la contrasenya"),
  privacy_policy: Yup.boolean()
    .oneOf([true], "Has d'acceptar la política de privacitat")
    .required("Has d'acceptar la política de privacitat"),
  img_url: Yup.string().url("Introdueix una URL vàlida").notRequired(),
});

const SignupPage = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    setError(null);
    setSuccess(false);

    try {
      const response = await axios.post(
        "https://api.fundwave.es/user/register",
        {
          ...values,
        }
      );

      console.log("Usuari registrat:", response.data);

      // Missatge d'èxit
      setSuccess(true);
      setTimeout(() => navigate("/login"), 2000); // Redirigir al login en 2 segons
    } catch (err) {
      console.error("Error al registrar:", err.response?.data || err.message);
      setError(
        "Error al registrar. Si us plau, verifica les dades introduïdes."
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container
      className="mdl-standard d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <Row className="w-100 d-flex justify-content-center">
        <Col md={{ span: 8 }}>
          <h2 className="text-center mb-4">Crea un Compte</h2>
          <Card
            className="rounded-5"
            style={{ boxShadow: "10px 10px 0px #01403A" }}
          >
            <Card.Body className="position-relative p-5">
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  email: "",
                  password: "",
                  confirm_password: "",
                  img_url: "",
                  privacy_policy: false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <FormikForm>
                    {error && <p className="text-danger">{error}</p>}
                    {success && (
                      <p className="text-success">
                        Registre complet! Redirigint al login...
                      </p>
                    )}
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="first_name" className="mb-3">
                          <Form.Label>Nom</Form.Label>
                          <Field
                            name="first_name"
                            type="text"
                            as={Form.Control}
                          />
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="last_name" className="mb-3">
                          <Form.Label>Cognoms</Form.Label>
                          <Field
                            name="last_name"
                            type="text"
                            as={Form.Control}
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group controlId="email" className="mb-3">
                      <Form.Label>Correu electrònic</Form.Label>
                      <Field name="email" type="email" as={Form.Control} />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="password" className="mb-3">
                          <Form.Label>Contrasenya</Form.Label>
                          <Field
                            name="password"
                            type="password"
                            as={Form.Control}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group
                          controlId="confirm_password"
                          className="mb-3"
                        >
                          <Form.Label>Confirma la contrasenya</Form.Label>
                          <Field
                            name="confirm_password"
                            type="password"
                            as={Form.Control}
                          />
                          <ErrorMessage
                            name="confirm_password"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group controlId="img_url" className="mb-3">
                      <Form.Label>Imatge de perfil (opcional)</Form.Label>
                      <Field name="img_url" type="text" as={Form.Control} />
                      <ErrorMessage
                        name="img_url"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group controlId="privacy_policy" className="mb-3">
                      <Form.Check
                        type="checkbox"
                        name="privacy_policy"
                        label="Accepto la política de privacitat"
                      />
                      <ErrorMessage
                        name="privacy_policy"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Button
                      type="submit"
                      className="w-100 mt-3"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Processant..." : "Registra't"}
                    </Button>
                  </FormikForm>
                )}
              </Formik>
              <div className="text-center mt-3">
                <Link to="/login" className="text-primary btn btn-link">
                  Ja tens un compte? Inicia sessió
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignupPage;
